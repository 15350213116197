@use "../../../variables.scss" as *;

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    background-color: var(--side-panel-background);
    min-height: 100%;
    display: flex;
    flex: 1;
    padding-top: var(--toolbar-vertical-padding);
    padding-bottom: var(--footer-height);

    .dx-treeview {

      // ## Long text positioning
      white-space: nowrap;

      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        flex-direction: row-reverse;
        border-radius: 0;

        .dx-icon {
          width: var(--side-panel-min-width) !important;
          margin: 0 !important;
        }
      }

      // ##

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // ##

      // ## Item levels customization
      .dx-treeview-node {

        // ## Arrow customization
        padding: 0 0 !important;

        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid var(--border-color);
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 0 0 var(--side-panel-min-width);
        }
      }
    }

    // ##
  }
}
