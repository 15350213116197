@use '../../variables.scss' as *;
@use 'sass:math';

.view.crm-contact-list {
  width: 100%;
  display: contents;

  .view-wrapper-contact-list {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: row;

    .grid {
      height: 100%;

      .name-template {
        .position {
          font-size: 12px;
          color: var(--texteditor-label-color);
        }
      }

      .dx-datagrid-header-panel {
        padding: 0 var(--content-padding);
      }

      .dx-toolbar {
        margin-bottom: 0;
        padding: var(--toolbar-margin-bottom) 0;
      }

      .dx-datagrid-content .dx-row {
        cursor: pointer;
      }

      .grid-header {
        @include header();
      }
    }

    .cell-info {
      position: relative;

      .status {
        font-size: 13px;
        position: absolute;
        margin-top: 10px;
        margin-left: 11px;
      }
    }
  }
}
