#uploader {
  background-color: var(--typography-bg);
  border-width: 1.5px;
  border-style: dashed;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 124px;
  user-select: none;
  line-height: inherit;
  margin-bottom: 10px;

  & > span {
    font-weight: 100;
    text-align: center;
  }
}
