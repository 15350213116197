@use "../../../variables.scss" as *;

.card-activities {
  padding: 10px;
  min-height: auto;
  position: relative;
  display: block;

  .activities-list {
    .dx-list-item {
      margin: 10px 0;
      overflow: visible;
      background: transparent;
    }
  }

  &.load {
    min-height: 300px;
  }
}

.activities-list {
  .dx-list-item-content {
    padding: 0;
    overflow: visible;
  }

  .activity {
    box-shadow: 0 1px 4px 0 #00000026;
    border-left: 2px solid var(--accent-color);
    margin-right: 1px;
    padding: 8px 2px 8px 16px;
    display: grid;
    grid-template-columns: 3fr 1fr 0fr;
    align-items: center;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .date {
      padding: 0 10px;
      font-size: 12px;
      color: var(--texteditor-label-color);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 5px;

      &.by {
        width: 170px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .card-activities {
    .activities-list {
      .activity {
        .date {
          grid-row-start: 2;
          padding: 0;
        }

        .overflow-menu {
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}
