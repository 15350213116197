@use "../../../variables" as *;

@include separator();

.change-profile-password-popup.form-popup {
  .dx-form {
    height: var(--change-password-popup-height, auto);
  }

  .dx-popup-normal {
    border-radius: 8px;
    padding: 8px 0;

    .dx-toolbar {
      padding: 0 24px 8px;
    }
  }
}
